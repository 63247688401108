import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';

import { isRewardAvailable } from './isRewardAvailable';
import { SimpleReward } from '../types/domain';
import { Experiments } from '../constants';

interface SortRewardsParams {
  flowAPI: ControllerFlowAPI;
  rewards: SimpleReward[];
  pointsBalance: number;
}

export function sortRewards({ flowAPI, rewards, pointsBalance }: SortRewardsParams): SimpleReward[] {
  const { experiments } = flowAPI;
  const sortDiscountRewardTypeFirst = experiments.enabled(Experiments.OrderDiscountChanges);

  return rewards.slice().sort((first, second) => {
    const isFirstRewardAvailable = isRewardAvailable({ requiredPoints: first.requiredPoints, pointsBalance });
    const isSecondRewardAvailable = isRewardAvailable({ requiredPoints: second.requiredPoints, pointsBalance });

    // Sort by availability
    if (isFirstRewardAvailable && !isSecondRewardAvailable) {
      return -1;
    }

    if (!isFirstRewardAvailable && isSecondRewardAvailable) {
      return 1;
    }

    // Sort by activation
    if (first.couponActivated && !second.couponActivated) {
      return -1;
    }

    if (!first.couponActivated && second.couponActivated) {
      return 1;
    }

    // Sort by type
    if (
      [RewardType.COUPON, RewardType.COUPON_REWARD].includes(first.type!) &&
      second.type === RewardType.DISCOUNT_AMOUNT
    ) {
      return sortDiscountRewardTypeFirst ? 1 : -1;
    }

    if (
      first.type === RewardType.DISCOUNT_AMOUNT &&
      [RewardType.COUPON, RewardType.COUPON_REWARD].includes(second.type!)
    ) {
      return sortDiscountRewardTypeFirst ? -1 : 1;
    }

    // Sort by date created
    if (first.dateCreated && second.dateCreated) {
      return second.dateCreated.getTime() - first.dateCreated.getTime();
    }

    return 0;
  });
}
